import twofaEnabled from './2fa-enabled';
import administrator from './administrator';
import authenticated from './authenticated';
import can from './can';
import changePassword from './change-password';
import clearCompany from './clear-company';
import eulaAccepted from './eula-accepted';
import moderator from './moderator';
import parentOrganisation from './parent-organisation';
import unauthenticated from './unauthenticated';

export default {
  '2fa-enabled': twofaEnabled,
  administrator,
  authenticated,
  can,
  'change-password': changePassword,
  'clear-company': clearCompany,
  'eula-accepted': eulaAccepted,
  moderator,
  'parent-organisation': parentOrganisation,
  unauthenticated,
};
