// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Vue and Store
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Markdown
import Markdown from 'vue3-markdown-it';

import vueMiddleware from 'vue-middleware';

// Sentry
import sentry from './plugins/sentry';

// Feature Flags
import featureFlags from './plugins/feature-flags';

// Stores
import { useActionStore } from './stores/action';
import { useActionDataStore } from './stores/actiondata';
import { useMitreStore } from './stores/mitre';
import { useUserStore } from './stores/user';
import { useApplicationStore } from './stores/application';
import { useOrganisationStore } from './stores/organisation';

// Axios & endpoint
import axios from './axios';

// Vue & routing
import App from './App.vue';
import router from './router';
import './index.scss';

// Plugins
import i18n from './plugins/i18n';
import './plugins/icons';
import permission from './plugins/permission';
import toast from './plugins/toast';
import enduringToast from './plugins/enduringToast';

// Add charts
import charts from './plugins/charts';

import middleware from './middleware';

// Create Store
const pinia = createPinia();
const app = createApp(App);

// Directives
app.directive('click-away', {
  beforeMount: (elem, binding) => {
    // eslint-disable-next-line no-param-reassign
    elem.clickAwayEvent = function clickAwayEvent(event) {
      if (!(elem === event.target || elem.contains(event.target))) {
        binding.value(event, elem);
      }
    };
    document.body.addEventListener(
      'click',
      elem.clickAwayEvent,
    );
  },
  unmounted: (elem) => {
    document.body.removeEventListener(
      'click',
      elem.clickAwayEvent,
    );
  },
});

// Set up global properties
app.config.globalProperties.$can = permission;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$enduringToast = enduringToast;

// Set up app
// eslint-disable-next-line vue/component-definition-name-casing
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(sentry);
app.use(featureFlags);
app.use(router);
app.use(vueMiddleware, { middleware, pageTitle: false });
app.use(axios);
app.use(i18n);
app.use(pinia.use(({ store }) => {
  // eslint-disable-next-line no-param-reassign
  store.$axios = app.config.globalProperties.$axios;
  // eslint-disable-next-line no-param-reassign
  store.$config = app.config.globalProperties.$config;
  // eslint-disable-next-line no-param-reassign
  store.$router = app.config.globalProperties.$router;
  // eslint-disable-next-line no-param-reassign
  store.$route = app.config.globalProperties.$route;
  // eslint-disable-next-line no-param-reassign
  store.$Sentry = app.config.globalProperties.$Sentry;
  // eslint-disable-next-line no-param-reassign
  store.$toast = app.config.globalProperties.$toast;
  // eslint-disable-next-line no-param-reassign
  store.$t = app.config.globalProperties.$t;
}));
app.use(charts);
app.use(Markdown);
app.mount('#app');

// Use store
app.config.globalProperties.$actionStore = useActionStore();
app.config.globalProperties.$actionDataStore = useActionDataStore();
app.config.globalProperties.$mitreStore = useMitreStore();
app.config.globalProperties.$userStore = useUserStore();
app.config.globalProperties.$applicationStore = useApplicationStore();
app.config.globalProperties.$organisationStore = useOrganisationStore();
