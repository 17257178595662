import { createI18n } from 'vue-i18n';

import components from '@slcyber/component-library/src/locale/en/components.json';
import account from '../locales/en/account.json';
import action from '../locales/en/action.json';
import administration from '../locales/en/administration.json';
import attribute from '../locales/en/attribute.json';
import audit from '../locales/en/audit.json';
import authentication from '../locales/en/authentication.json';
import bookmarks from '../locales/en/bookmarks.json';
import common from '../locales/en/common.json';
import company from '../locales/en/company.json';
import dashboard from '../locales/en/dashboard.json';
import dictionary from '../locales/en/dictionary.json';
import error from '../locales/en/error.json';
import eula from '../locales/en/eula.json';
import executive from '../locales/en/executive.json';
import exportLocale from '../locales/en/export.json';
import finance from '../locales/en/finance.json';
import general from '../locales/en/general.json';
import home from '../locales/en/home.json';
import markets from '../locales/en/markets.json';
import mitre from '../locales/en/mitre.json';
import navigation from '../locales/en/navigation.json';
import news from '../locales/en/news.json';
import notification from '../locales/en/notification.json';
import organisation from '../locales/en/organisation.json';
import pagination from '../locales/en/pagination.json';
import permission from '../locales/en/permission.json';
import port from '../locales/en/port.json';
import reports from '../locales/en/reports.json';
import riskReports from '../locales/en/risk_reports.json';
import search from '../locales/en/search.json';
import spycloud from '../locales/en/spycloud.json';
import support from '../locales/en/support.json';
import tier from '../locales/en/tier.json';
import traffic from '../locales/en/traffic.json';
import user from '../locales/en/user.json';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  availableLocales: ['en'],
  messages: {
    en: {
      ...account,
      ...action,
      ...administration,
      ...attribute,
      ...audit,
      ...authentication,
      ...bookmarks,
      ...common,
      ...company,
      ...components,
      ...dashboard,
      ...dictionary,
      ...error,
      ...eula,
      ...executive,
      ...exportLocale,
      ...finance,
      ...general,
      ...home,
      ...markets,
      ...mitre,
      ...navigation,
      ...news,
      ...notification,
      ...organisation,
      ...pagination,
      ...permission,
      ...port,
      ...reports,
      ...riskReports,
      ...search,
      ...spycloud,
      ...support,
      ...tier,
      ...traffic,
      ...user,
    },
  },
});

export default i18n;
