import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import axios from 'axios';
import config from '../../config';

import { useUserStore } from './user';
import { useOrganisationStore } from './organisation';

// eslint-disable-next-line import/prefer-default-export
export const useApplicationStore = defineStore('application', {
  state: () => ({
    company: useStorage('company', null),
    toasts: useStorage('toasts', []),
    enduringToast: useStorage('enduringToast', []),
    appLoadComplete: false,
    appLoading: false,
  }),
  actions: {
    async appLoad() {
      this.appLoading = true;
      const organisationStore = useOrganisationStore();
      const userStore = useUserStore();
      await userStore.fetchUser();
      if (userStore.loggedIn) {
        await userStore.fetchPermissions();
        this.appLoadComplete = true;
        this.appLoading = false;
        this.clearAllToast();
        organisationStore.getTierLimits();
        userStore.getNotificationCount();
        userStore.getBookmarks();
      } else {
        this.appLoadComplete = true;
        this.appLoading = false;
      }
    },
    getCompany(id) {
      if (id) {
        axios.get(
          `${config.endpoints.companies.index}/${id}`,
          { params: { load_discovered_counts: 1 } },
        ).then((response) => {
          this.updateCompany(response.data.source);
        });
      }
    },
    setCompany(company) {
      if (company.id && this.company?.id) {
        this.getCompany(company.id);
      }
      this.company = company;
    },
    updateCompany(company) {
      this.company = company ? { ...this.company, ...company } : null;
    },
    clearCompany() {
      this.updateCompany(null);
    },
    clearToast() {
      this.toasts.shift();
    },
    clearAllToast() {
      this.toasts = [];
    },
    createToast(toast) {
      this.toasts.push(toast);
      setTimeout(() => {
        const index = this.toasts.indexOf(toast);
        this.removeToast(index);
      }, 4500);
    },
    removeToast(index) {
      this.toasts.splice(index, 1);
      setTimeout(() => this.clearToast, 4500);
    },
    setEnduringToast(toast) {
      this.enduringToast = toast.content;
    },
  },
  getters: {
    companyScanInProgress() {
      if (this.company) {
        return ['discovery', 'threat_queued', 'threat_scan']
          .includes(this.company.stage);
      }
      return false;
    },
  },
});
