import { useUserStore } from '../stores/user';

export default ({ redirect }) => {
  const userStore = useUserStore();
  if (
    userStore.loggedIn &&
    !userStore.user.two_factor_confirmed_at &&
    (userStore.organisation.enforced_2fa === true ||
      userStore.organisation.enforced_2fa === 1)
  ) {
    redirect({ name: 'account-security', query: { enable_2fa: true } });
  }
};
