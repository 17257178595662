import { useUserStore } from '../stores/user';

export default ({ redirect }) => {
  const userStore = useUserStore();
  if (
    userStore.loggedIn && (
      userStore.user.site_role !== 'Administrator' ||
      userStore.user.site_role !== 'Moderator'
    )
  ) {
    redirect({ name: 'home' });
  }
};
