import { useApplicationStore } from '../stores/application';

export default ({ to, from }) => {
  const applicationStore = useApplicationStore();

  if (to?.name === 'companies-create' && from?.name !== 'companies-create') {
    applicationStore.clearCompany();
  }

  if (from?.name?.includes('companies-id') && !to?.name?.includes('companies')) {
    applicationStore.clearCompany();
  }
};
