import * as Sentry from '@sentry/vue';
import router from '../router';

export default {
  install: (app) => {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        // Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be
      // enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    });
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$Sentry = Sentry;
  },
};
