export default {
  computed: {
    siteId() {
      switch (this.$config.env) {
        case 'production':
          return 3;
        case 'development':
          return 4;
        default:
          return 4;
      }
    },
    matomoEnabled() {
      if (
        this.siteId &&
        this.$userStore.loggedIn &&
        !(
          this.siteId === 3 &&
          this.$userStore.user.email.endsWith('@slcyber.io')
        )
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.initialiseMatomo();
  },
  methods: {
    initialiseMatomo() {
      // eslint-disable-next-line no-underscore-dangle, no-var, no-multi-assign
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['requireCookieConsent']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      const u = '//matomo.slcdev.cc/';
      // eslint-disable-next-line no-undef
      _paq.push(['setTrackerUrl', `${u}matomo.php`]);
      // eslint-disable-next-line no-undef
      _paq.push(['setSiteId', this.siteId]);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = `${u}matomo.js`;
      s.parentNode.insertBefore(g, s);
    },
  },
  watch: {
    $route(to) {
      if (this.matomoEnabled) {
        // eslint-disable-next-line no-undef
        _paq.push(['setCustomUrl', to.path]);
        // eslint-disable-next-line no-undef
        _paq.push(['trackPageView']);
      }
    },
  },
};
