export default {
  methods: {
    arrayChunker(array, perChunk) {
      return array.reduce((resultArray, item, index) => {
        const reducedArray = resultArray;
        const chunkIndex = Math.floor(index / perChunk);

        if (!reducedArray[chunkIndex]) {
          reducedArray[chunkIndex] = []; // start a new chunk
        }

        reducedArray[chunkIndex].push(item);
        return reducedArray;
      }, []);
    },
    getTestId(value, type) {
      // eslint-disable-next-line no-param-reassign
      value += type;
      return value.toLowerCase().replace('&', 'and').split(/ |, |[.] |_|,/).join('-');
    },
  },
};
