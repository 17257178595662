import { createRouter, createWebHistory } from 'vue-router';

// Middleware. Import in meta middleware dose not work.
import { useApplicationStore } from './stores/application';

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes: [
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('./layouts/error.vue'),
    },
    {
      path: '/401',
      name: '401',
      component: () => import('./layouts/error.vue'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import('./layouts/error.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./layouts/error.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./components/authentication/LoginForm.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: ['unauthenticated'],
      },
    },
    {
      path: '/two-factor-challenge',
      name: 'two-factor-challenge',
      component: () => import('./components/authentication/TwoFactorLoginForm.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: ['unauthenticated'],
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./pages/auth/logout.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: ['unauthenticated'],
      },
    },
    {
      path: '/forgotten-password',
      name: 'forgotten-password',
      component: () => import('./components/authentication/ForgottenPasswordForm.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: ['unauthenticated'],
      },
    },
    {
      path: '/login/forgotten-password/change-password',
      name: 'change-password',
      component: () => import('./components/authentication/ChangePasswordForm.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: ['unauthenticated'],
      },
    },
    {
      path: '/administration',
      name: 'administration',
      component: () => import('./pages/administration/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'administrator',
          'clear-company',
        ],
      },
      children: [
        {
          path: 'users',
          name: 'administration-users',
          component: () => import('./pages/administration/users/index.vue'),
        },
        {
          path: 'organisations',
          name: 'administration-organisations',
          component: () => import('./pages/administration/organisations/index.vue'),
          children: [
            {
              path: 'store',
              name: 'administration-organisations-store',
              component: () => import('./pages/administration/organisations/store.vue'),
            },
            {
              path: ':id',
              name: 'administration-organisations-id',
              component: () => import('./pages/administration/organisations/_id/index.vue'),
              children: [
                {
                  path: 'update',
                  name: 'administration-organisations-id-update',
                  component: () => import(
                    './pages/administration/organisations/_id/update.vue'
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'tiers',
          name: 'administration-tiers',
          component: () => import('./pages/administration/tiers/index.vue'),
          children: [
            {
              path: ':id',
              name: 'administration-tiers-id',
              component: () => import('./pages/administration/tiers/_id.vue'),
            },
            {
              path: 'store',
              name: 'administration-tiers-store',
              component: () => import('./pages/administration/tiers/store.vue'),
            },
          ],
        },
        {
          path: 'news',
          name: 'administration-news',
          component: () => import('./pages/administration/news/index.vue'),
          children: [
            {
              path: 'store',
              name: 'administration-news-store',
              component: () => import('./pages/administration/news/store.vue'),
            },
            {
              path: ':slug',
              name: 'administration-news-slug',
              component: () => import('./pages/administration/news/_slug.vue'),
            },
          ],
        },
        {
          path: 'finance',
          name: 'administration-finance',
          component: () => import('./pages/administration/finance/index.vue'),
          children: [
            {
              path: 'credits',
              name: 'administration-finance-credits',
              component: () => import('./pages/administration/finance/credits/index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '',
      redirect: () => 'home',
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('./pages/home/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
        ],
      },
    },
    {
      path: '/markets/:market',
      name: 'markets-market',
      component: () => import('./pages/companies/_id/actions/market.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/markets/:market/vendor/:slug',
      name: 'markets-market-vendor-slug',
      component: () => import('./pages/companies/_id/actions/vendor.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('./pages/companies/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
      children: [
        {
          path: 'create',
          name: 'companies-create',
          component: () => import('./pages/companies/create.vue'),
          meta: {
            middleware: [
              'clear-company',
              'parent-organisation',
            ],
          },
        },
        {
          path: ':id/dashboard',
          name: 'companies-id-dashboard',
          component: () => import('./pages/companies/_id/dashboard.vue'),
        },
        {
          path: ':id/attributes',
          name: 'companies-id-attributes',
          component: () => import('./pages/companies/_id/attributes.vue'),
          meta: {
            middleware: [
              'authenticated',
              'eula-accepted',
              'change-password',
              '2fa-enabled',
              'can:company-attributes',
            ],
          },
        },
        {
          path: ':id/actions',
          name: 'companies-id-actions',
          component: () => import('./pages/companies/_id/actions/index.vue'),
        },
        {
          path: ':id/actions/:action',
          name: 'companies-id-actions-action',
          component: () => import('./pages/companies/_id/actions/_action.vue'),
        },
        {
          path: ':id/explore',
          name: 'companies-id-explore',
          component: () => import('./pages/companies/_id/explore.vue'),
        },
        {
          path: ':id/traffic',
          name: 'companies-id-traffic',
          component: () => import('./pages/companies/_id/traffic.vue'),
        },
        {
          path: ':id/report',
          name: 'companies-id-report',
          component: () => import('./pages/companies/_id/report.vue'),
        },
        {
          path: ':id/settings',
          name: 'companies-id-settings',
          component: () => import('./pages/companies/_id/settings.vue'),
        },
        {
          path: ':id/history',
          name: 'companies-id-history',
          component: () => import('./pages/companies/_id/history.vue'),
        },
      ],
    },
    {
      path: '/risk-reports',
      name: 'risk-reports',
      component: () => import('./pages/risk-reports/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
          'can:use-risk-reports',
        ],
      },
      children: [
        {
          path: '/risk-reports/:id',
          name: 'risk-report',
          component: () => import('./pages/risk-reports/_id.vue'),
        },
      ],
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('./pages/search/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/bookmarks',
      name: 'bookmarks',
      component: () => import('./pages/bookmarks/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./pages/news/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
      children: [
        {
          path: ':slug',
          name: 'news-slug',
          component: () => import('./pages/news/_slug.vue'),
        },
      ],
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('./pages/notifications/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('./pages/account/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
      children: [
        {
          path: 'organisation',
          name: 'account-organisation',
          component: () => import('./pages/account/organisation/index.vue'),
          meta: {
            middleware: [
              'parent-organisation',
            ],
          },
        },
        {
          path: 'security',
          name: 'account-security',
          component: () => import('./pages/account/security/index.vue'),
          meta: {
            excludeMiddleware: [
              'change-password',
              '2fa-enabled',
              'clear-company',
            ],
          },
        },
        {
          path: 'token',
          name: 'account-token',
          component: () => import('./pages/account/token/index.vue'),
          meta: {
            middleware: [
              'parent-organisation',
            ],
          },
        },
        {
          path: 'exports',
          name: 'account-exports',
          component: () => import('./pages/account/exports/index.vue'),
        },
        {
          path: 'audit',
          name: 'account-audit',
          component: () => import('./pages/account/audit/index.vue'),
        },
      ],
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('./pages/support/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'eula-accepted',
          'change-password',
          '2fa-enabled',
          'clear-company',
        ],
      },
    },
    {
      path: '/eula',
      name: 'eula',
      component: () => import('./pages/eula/index.vue'),
      meta: {
        middleware: [
          'authenticated',
          'clear-company',
        ],
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Run appLoad if it hasnt already been run
  const appStore = useApplicationStore();
  if (
    !from.name &&
    !appStore.appLoading &&
    !appStore.appLoadComplete
  ) {
    await appStore.appLoad();
  }

  next();
});

export default router;
