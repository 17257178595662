import { defineStore } from 'pinia';

// eslint-disable-next-line import/prefer-default-export
export const useActionDataStore = defineStore('actionData', {
  state: () => ({
    loadingActionData: true,
    selectedActionData: [],
    actionData: [],
    count: null,
    currentPage: 1,
  }),
  actions: {
    async fetchActionData(page = this.currentPage) {
      this.selectedActionData = [];
      this.loadingActionData = true;
      const url = this.$config.endpoints.companies.action_data.index
        .replace('{company_id}', this.$router.currentRoute.params.id)
        .replace('{action_id}', this.$router.currentRoute.params.action);
      await this.$axios.get(url, {
        params: {
          status: this.$router.currentRoute.query.status,
          page,
        },
      }).then((response) => {
        this.currentPage = page;
        this.count = response.data.data.total;
        this.actionData = response.data.data.data;
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      }).finally(() => {
        this.loadingActionData = false;
      });
    },
    async resolveActionData(resolution = null) {
      const url = this.$config.endpoints.companies.action_data.resolve
        .replace('{company_id}', this.$router.currentRoute.params.id)
        .replace('{action_id}', this.$router.currentRoute.params.action);
      await this.$axios.post(url, {
        ids: this.selectedActionData,
        resolution,
      }).then(async () => {
        await this.fetchActionData();
        if (this.count === 0) {
          this.$router.back();
        }
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      });
    },
    async restoreActionData() {
      const url = this.$config.endpoints.companies.action_data.restore
        .replace('{company_id}', this.$router.currentRoute.params.id)
        .replace('{action_id}', this.$router.currentRoute.params.action);
      await this.$axios.post(url, {
        ids: this.selectedActionData,
      }).then(async () => {
        await this.fetchActionData();
        if (this.count === 0) {
          this.$router.back();
        }
      }).catch((error) => {
        this.$Sentry.captureException(error);
        this.$toast(error.response.data.message, 'warning');
      });
    },
    selectAllActionData() {
      this.selectedActionData = this.selectedAll ?
        [] :
        this.actionData.map((data) => data.id);
    },
  },
  getters: {
    selectedAll() {
      return this.selectedActionData?.length === this.actionData?.length;
    },
  },
});
