import { defineStore } from 'pinia';

// eslint-disable-next-line import/prefer-default-export
export const useMitreStore = defineStore('mitre', {
  state: () => ({
    path: 'actions',
    content: null,
    showPanel: false,
  }),
  actions: {
    togglePanel(path = this.path) {
      this.path = path;
      this.showPanel = !this.showPanel;
    },
    navigate(path) {
      this.path = path;
    },
    async fetchContent() {
      if (this.path) {
        const response = await fetch(`${this.$config.endpoints.mitre_bucket}/${this.path}/index.md`);
        this.content = await response.text();
      }
    },
  },
});
