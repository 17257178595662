<template>
  <div v-if="appLoadComplete">
    <FeatureFlagProvider>
      <Component
        :is="layout"
        v-if="$route.name"
      >
        <router-view v-slot="{ Component }">
          <transition
            name="page"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </Component>
    </FeatureFlagProvider>
  </div>
  <div
    v-if="!appLoadComplete"
    class="flex h-screen w-screen items-center justify-center"
  >
    <SlLoading
      size="x-large"
      text=""
    />
  </div>
</template>

<script>
import { FeatureFlagProvider } from '@slcyber/vue-feature-flag-provider';
import { SlLoading } from '@slcyber/component-library';
import { mapState } from 'pinia';
import AuthLayout from './layouts/AuthLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';
import { useApplicationStore } from './stores/application';

export default {
  components: {
    AuthLayout,
    DefaultLayout,
    SlLoading,
    FeatureFlagProvider,
  },
  computed: {
    ...mapState(useApplicationStore, ['appLoadComplete']),
    layout() {
      return this.$route.meta?.layout ?? 'DefaultLayout';
    },
  },
};
</script>

<style scoped lang="scss">

.page-enter-from,
.page-leave-to
{
  opacity: 0;
  max-height: 170px;
}

.page-enter-to,
.page-leave-from
{
  opacity: 1;
  max-height: 1200px;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.1s ease-in-out;
}
</style>
