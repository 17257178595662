import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { isEqual } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore('user', {
  state: () => ({
    notifications: 0,
    bookmarks: [],
    locale: useStorage('locale', 'en'), // todo - Hook this up properly to the user locale.
    user: null,
    permissions: [],
    organisation: null,
    samlProvider: null,
    loggedIn: false,
  }),
  actions: {
    async fetchPermissions() {
      return this.$axios.get(this.$config.endpoints.permissions).then((response) => {
        this.permissions = response.data.source;
      });
    },
    async fetchUser() {
      return this.$axios.get(this.$config.endpoints.auth.user)
        .then((res) => {
          const { user } = res.data;
          this.samlProvider = user.organisation.saml_provider;
          delete user.organisation.saml_provider;
          this.organisation = user.organisation;
          delete user.organisation;
          this.user = user;
          this.loggedIn = true;
        }).catch((error) => {
          if (error.response.status === 401) {
            this.handleUnauthorised();
          } else {
            this.$Sentry.captureException(error);
          }
        });
    },
    async updateUser(user, showToasts = true) {
      const response = await this.$axios.patch(
        `${this.$config.endpoints.users}/${this.user.id}`,
        {
          accepted_eula: user.accepted_eula,
          email: user.email,
          must_change_password: user.must_change_password,
          password: user.password,
          password_confirm: user.password_confirm,
        },
      ).then((res) => {
        this.user = {
          ...this.user,
          ...res.data.user,
        };
        if (showToasts) {
          this.$toast(res.data.message, 'success');
        }
        return res;
      }).catch((error) => {
        this.$Sentry.captureException(error);
        if (showToasts) {
          this.$toast(error.response.data.message, 'warning');
        }
        throw error;
      });
      if (user.profile && !isEqual(this.user.profile, user.profile)) {
        return this.updateProfile(user.profile, showToasts);
      }
      return response;
    },
    async updateProfile(profile, showToasts = true) {
      return this.$axios.patch(
        `${this.$config.endpoints.profiles}/${this.user.profile.id}`,
        profile,
      ).then((response) => {
        this.user.profile = response.data.profile;
        if (showToasts) {
          this.$toast(response.data.message, 'success');
        }
        return response;
      }).catch((error) => {
        this.$Sentry.captureException(error);
        if (showToasts) {
          this.$toast(error.response.data.message, 'warning');
        }
        throw error;
      });
    },
    handleUnauthorised(redirect = false) {
      if (this.organisationSamlEnabled) {
        const url = `/api/saml/${this.samlProvider.slug}/login`;
        this.$reset();
        window.location.href = url;
      } else {
        this.$reset();
        if (redirect) {
          this.$router.push({ name: 'login' });
        }
      }
    },
    logout() {
      this.$axios.post(this.$config.endpoints.auth.logout).then(() => {
        this.loggedIn = false;
        this.user = null;
        this.organisation = null;
        this.permissions = [];
        if (this.organisationSamlEnabled) {
          this.$router.push({ name: 'logout' });
        } else {
          this.$router.push({ name: 'login' });
        }
      });
    },
    setNotifications(count) {
      this.notifications = count;
    },
    getNotificationCount() {
      this.$axios.get(this.$config.endpoints.notifications.index, {
        params: {
          include_count: 1,
        },
      }).then((response) => {
        this.setNotificationCount(response.data.source.total);
      });
    },
    setNotificationCount(count) {
      this.notifications = count;
    },
    updateNotificationCount(count) {
      this.setNotificationCount(count);
    },
    getBookmarks() {
      this.$axios.get(this.$config.endpoints.bookmarks, {
        params: {
          document_ids_only: 1,
        },
      }).then((response) => {
        this.setBookmarkDocumentIds(response);
      });
    },
    setBookmarkDocumentIds(bookmarks) {
      this.bookmarks = bookmarks.data.source;
    },
    addBookmarks(documentId) {
      this.bookmarks.push(documentId);
    },
    deleteBookmark(documentId) {
      const index = this.bookmarks.findIndex((item) => item === documentId);
      this.bookmarks.splice(index, 1);
    },
  },
  getters: {
    organisationEnforced2fa: (state) => !!state.organisation?.enforced_2fa,
    organisationIsParent: (state) => !state.organisation?.parent_organisation_id,
    organisationHasValidVirusTotalToken: (state) => !!state.organisation?.has_virus_total_api_key,
    organisationSamlEnabled: (state) => !!state.samlProvider,
  },
});
