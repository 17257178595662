import { config } from '@fortawesome/fontawesome-svg-core';
import library from '@slcyber/component-library/icons';

/**
 * FontAwesome Light Library
 *
 * This is our default library so we're importing all the icons for future use.
 */
import { fal } from '@fortawesome/pro-light-svg-icons';

/**
 * FontAwesome Brands Library
 *
 * As our use of Brands is very limited we're making explicit calls to import icons we want to use.
 */
import {
  faBitcoin,
  faBtc,
  faCcVisa,
  faMonero,
} from '@fortawesome/free-brands-svg-icons';

/**
 * FontAwesome Duo Tone Library
 *
 * As our use of Due Tone is very limited we're making explicit calls
 * to import icons we want to use.
 */
import {
  faClipboardListCheck,
  faComments,
  faCreditCardFront,
  faFolderOpen,
  faShoppingCart,
  faSpinnerThird,
  faUserCrown,
} from '@fortawesome/pro-duotone-svg-icons';

/**
 * FontAwesome Solid Library
 *
 * As our use of Solid is very limited we're making explicit calls to import icons we want to use.
 */
import {
  faArrowDown,
  faArrowUp,
  faBagsShopping,
  faBallotCheck,
  faBell,
  faBooks,
  faBooksMedical,
  faBoxes,
  faBrush,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClipboardList,
  faCog,
  faCommentLines,
  faCommentsAlt,
  faCopy,
  faDesktop,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faFile,
  faFileSearch,
  faFilter,
  faFingerprint,
  faGlobe,
  faHeart,
  faHeartbeat,
  faHistory,
  faHomeLg,
  faInfoCircle,
  faKeySkeleton,
  faLaptopHouse,
  faLayerGroup,
  faList,
  faListOl,
  faLock,
  faMinusCircle,
  faNetworkWired,
  faNewspaper,
  faRotateLeft,
  faPenAlt,
  faPlus,
  faPlusCircle,
  faPortalEnter,
  faPoundSign,
  faPrint,
  faSignIn,
  faSignOut,
  faSave,
  faSearch,
  faSensorAlert,
  faShareSquare,
  faShieldCheck,
  faShippingFast,
  faSignOutAlt,
  faStar,
  faStore,
  faTable,
  faTachometerAltFastest,
  faThList,
  faThumbsUp,
  faTint,
  faTrafficCone,
  faTrash,
  faTrashAlt,
  faTrashRestore,
  faUpload,
  faUsers,
  faWindowClose,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faEdit as farEdit,
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faFileUpload as fasFileUpload,
} from '@fortawesome/free-solid-svg-icons';

config.autoAddCss = false;

// Add all pro-light icons
library.add(fal);

// Add free-brand icons
library.add(faCcVisa, faMonero, faBitcoin, faBtc);

// Add pro-duotone icons
library.add(faCreditCardFront, faClipboardListCheck, faSpinnerThird, faUserCrown, faComments);

// Add pro-solid icons
library.add([
  faArrowDown,
  faArrowUp,
  faBagsShopping,
  faBallotCheck,
  faBell,
  faBooks,
  faBooksMedical,
  faBoxes,
  faBrush,
  faBuilding,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClipboardList,
  faCog,
  faCommentLines,
  faComments,
  faCommentsAlt,
  faCopy,
  faCreditCardFront,
  faDesktop,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faFile,
  faFileSearch,
  faFilter,
  faFingerprint,
  faFolderOpen,
  faGlobe,
  faHeart,
  faHeartbeat,
  faHistory,
  faHomeLg,
  faInfoCircle,
  faKeySkeleton,
  faLaptopHouse,
  faLayerGroup,
  faList,
  faListOl,
  faLock,
  faMinusCircle,
  faNetworkWired,
  faNewspaper,
  faRotateLeft,
  faPenAlt,
  faPlus,
  faPlusCircle,
  faPortalEnter,
  faPoundSign,
  faPrint,
  faSave,
  faSearch,
  faSensorAlert,
  faShareSquare,
  faShieldCheck,
  faShippingFast,
  faShoppingCart,
  faSignIn,
  faSignOut,
  faSignOutAlt,
  faStar,
  faStore,
  faTable,
  faTachometerAltFastest,
  faThList,
  faThumbsUp,
  faTint,
  faTrafficCone,
  faTrash,
  faTrashAlt,
  faTrashRestore,
  faUpload,
  faUsers,
  faWindowClose,
]);

// Add free-regular icons
library.add(farEdit, farCheckCircle, farTimesCircle);

// Add free-solid icons
library.add(fasFileUpload);

// export default library;

export default {
  install: (app) => {
    app.use(library);
  },
};
