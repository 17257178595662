export default {
  endpoints: {
    actions: '/api/actions',
    alerts: '/api/alerts',
    audit: '/api/audit',
    auth: {
      change_password: '/api/auth/user/password',
      generate_token: '/api/auth/generate-token',
      login: '/api/auth/login',
      logout: '/api/auth/logout',
      reset: '/api/auth/forgot-password',
      reset_password: '/api/auth/reset-password',
      sanctum_cookie: '/api/sanctum/csrf-cookie',
      two_factor: {
        backup_codes: '/api/auth/user/two-factor-recovery-codes',
        challenge: '/api/auth/two-factor-challenge',
        confirm: '/api/auth/user/confirmed-two-factor-authentication',
        index: '/api/auth/user/two-factor-authentication',
        qr_code: '/api/auth/user/two-factor-qr-code',
      },
      user: '/api/auth/user',
    },
    bookmarks: '/api/bookmarks',
    bulk_action: {
      add_importance: '/api/companies/{company_id}/actions/bulk/add-importance',
      export: '/api/companies/{company_id}/actions/bulk/export',
      remove_importance: '/api/companies/{company_id}/actions/bulk/remove-importance',
      resolve: '/api/companies/{company_id}/actions/bulk/resolve',
      restore: '/api/companies/{company_id}/actions/bulk/restore',
    },
    companies: {
      action_index: '/api/companies/{company_id}/actions',
      action_counts: '/api/companies/{company_id}/actions/counts',
      action_breakdown: '/api/action/breakdown',
      action_burndown: '/api/action/burndown',
      action_data: {
        index: '/api/companies/{company_id}/actions/{action_id}/action-data',
        resolve: '/api/companies/{company_id}/actions/{action_id}/action-data/resolve',
        restore: '/api/companies/{company_id}/actions/{action_id}/action-data/restore',
      },
      action_data_breach_info: '/api/actiondata/breach-info',
      action_priority_counts: '/api/action/counts-by-priority',
      action_show: '/api/companies/{company_id}/actions/{action_id}',
      aggregations: '/api/action/aggregations',
      attributes: {
        bulk: '/api/attributes/bulk',
        validate: '/api/attributes/bulk/validate',
        destroy_all: '/api/attribute/delete_attributes',
        index: '/api/attributes',
        restore: '/api/attribute/restore',
        hardDelete: '/api/attribute/hard_delete',
        restore_all: '/api/attribute/restore_attributes',
        update_all: '/api/attribute/update_attributes',
      },
      bucket_file: {
        index: '/api/bucket-files/{action_id}',
      },
      delete_actions: '/api/action/delete',
      delete_all_actions: '/api/action/delete-all',
      dictionaries: '/api/company/dictionaries',
      gits: '/api/action/git',
      health: '/api/companies/{company_id}/health',
      index: '/api/companies',
      industries: '/api/company/industries',
      jobs: {
        discovery: '/api/company/job/discovery',
        threat_scan: '/api/company/job/threat_scan',
      },
      limited_access: '/api/company/limited_access',
      phishing_screenshot: '/api/phishing-screenshot',
      ports: '/api/action/ports',
      ransomware: '/api/actiondata/ransomware',
      renew: '/api/company/renew',
      results: '/api/company/results',
      results_count: '/api/company/results_count',
      revoke: '/api/company/revoke',
      sectors: '/api/company/sectors',
      share: '/api/company/share',
      stage: '/api/company/stage',
      traffic: {
        index: '/api/company/traffic',
        filters: '/api/company/traffic/filters',
        traffic_map: '/api/company/traffic/traffic_map',
        traffic_table: '/api/company/traffic/traffic_table',
        incoming_traffic_stats: '/api/company/traffic/incoming_stats_overview',
        incoming_traffic_breakdown: '/api/company/traffic/traffic_breakdown',
        outgoing_traffic_graph: '/api/company/traffic/outgoing_traffic_graph',
        incoming_traffic_graph: '/api/company/traffic/incoming_traffic_graph',
      },
      vulnerabilities: '/api/action/vulnerabilities',
    },
    finance: {
      credits: {
        detail: '/api/finance/credits/detail',
        months: '/api/finance/credits/months',
        overview: '/api/finance/credits/overview',
      },
    },
    executives: '/api/executives',
    export: '/api/export',
    export_all: '/api/export-all',
    manager: {
      users: '/api/manager/users',
    },
    markets: {
      aggregations: '/api/market/aggregations',
      index: '/api/markets',
      listings: '/api/market/listings',
      listing_image: '/api/market/listings/image',
      listings_timeline: '/api/market/listings_timeline',
      document: '/api/market/document',
      market: '/api/market/market',
      top_vendors: '/api/market/top_vendors',
      vendor: '/api/market/vendor',
    },
    mitre_bucket: 'https://darkiq-mitre.ams3.digitaloceanspaces.com',
    news: {
      index: '/api/news',
      search: '/api/new/search',
    },
    notifications: {
      index: '/api/notifications',
      destroy_all: '/api/notification/delete_notifications',
      update_all: '/api/notification/update_notifications',
    },
    organisations: {
      feature_visibility: '/api/organisation/{id}/feature-visibility',
      counts: '/api/organisation/counts',
      credits: '/api/organisation/credits',
      index: '/api/organisations',
      limits: '/api/organisation/limits',
      simple_overview: '/api/organisation/simple-overview',
      virus_total: {
        index: '/api/organisation/virustotal',
        check: '/api/organisation/virustotal-check',
        widget_generator: '/api/organisation/virustotal-widget',
      },
    },
    pages: '/api/pages',
    permissions: '/api/permissions',
    profiles: '/api/profiles',
    refresh: '/api/refresh',
    reports: {
      image: '/api/reports/image',
      risk: '/api/reports/risk',
      risk_template: '/api/reports/risk/template',
    },
    search: {
      conversation: '/api/search/conversation',
      documents: '/api/search/documents',
      search: '/api/search',
      show: '/api/search/show',
    },
    stats: {
      general: '/api/stats/general',
    },
    support: '/api/support',
    tier: '/api/tier',
    tier_limits: '/api/tier/limits',
    tiers: '/api/tiers',
    user: {
      restore: '/api/user/{id}/restore',
      resend_authentication_email: '/api/user/{id}/resend_authentication_email',
      reset_password: '/api/user/{id}/reset_password',
      reset_2fa: '/api/user/{id}/reset_2fa',
    },
    users: '/api/users',
  },
  links: {
    administration: {
      organisations: {
        store: '/administration/organisations/store',
      },
    },
  },
};
