<template>
  <div>
    <div
      v-if="showEnduringToast"
      class="fixed right-0 top-0 z-50 mr-4 mt-4"
    >
      <EnduringToast
        :toast="$applicationStore.enduringToast"
      />
    </div>
    <div
      class="fixed right-0 z-50 mr-4 mt-4"
      :class="showEnduringToast ? 'top-16' : 'top-0'"
    >
      <transition-group
        name="fade"
        tag="div"
      >
        <SiteToast
          v-for="(toast, index) in $applicationStore.toasts"
          :key="index + 1"
          :toast="toast"
          @remove-toast="removeToast(index)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import SiteToast from './SiteToast.vue';
import EnduringToast from './EnduringToast.vue';

export default {
  name: 'SiteToasts',
  components: {
    EnduringToast,
    SiteToast,
  },
  data() {
    return {
      hideEnduringToastRoutes: [
        'companies-id-attributes',
        'companies-id-settings',
      ],
    };
  },
  computed: {
    showEnduringToast() {
      return (this.$applicationStore.enduringToast &&
        this.$applicationStore.enduringToast.prefix &&
        this.$route.name.startsWith('companies-id-') &&
        !this.hideEnduringToastRoutes.includes(this.$route.name));
    },
  },
  methods: {
    removeToast(index) {
      this.$applicationStore.removeToast(index);
    },
  },
};
</script>
