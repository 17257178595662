<template>
  <div class="flex h-screen w-screen bg-white text-sl-black-600">
    <SiteToasts v-if="$applicationStore.toasts.length" />
    <img
      class="absolute left-4 top-4 w-36"
      src="../assets/images/searchlight_logo_dark.svg"
      alt="Searchlight logo"
    >
    <div class="flex h-full w-1/2 items-center justify-end">
      <div class="mr-16 w-3/5">
        <h1 class="w-max text-7xl font-extrabold uppercase leading-none tracking-tighter text-sl-orange-700">
          {{ $t('home.title') }}
        </h1>
        <h2 class="mb-4 mt-1 w-5/6 uppercase leading-none text-sl-black-800 sm:max-w-xl md:text-6xl">
          {{ $t('home.subtitle') }}
        </h2>
        <slot />
      </div>
    </div>
    <img
      id="login-image"
      class="h-full w-1/2"
      src="../assets/images/darkiq-login-background.webp"
    >
    <div class="absolute bottom-4 right-4">
      <img
        src="../assets/images/Accreditation_Logos_x4-01.png"
        class="h-16"
        alt="Searchlight Cyber ISO-27001 Cyber Essentials AICPA SOC
      Crown Commercial Service Supplier Certification"
      >
    </div>
  </div>
</template>

<script>
import SiteToasts from '../components/toast/SiteToasts.vue';

export default {
  name: 'LoginLayout',
  components: {
    SiteToasts,
  },
};
</script>

<style lang="scss" scoped>
#login-image {
  object-fit: cover;
  clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

h1 {
  @apply font-[BarlowCondensed-SemiBold];
}

h2 {
  @apply font-[BarlowCondensed-Medium];
}

@font-face {
  font-family: "BarlowCondensed-Medium";
  src: url("../assets/fonts/BarlowCondensed-Medium.ttf");
}

@font-face {
  font-family: "BarlowCondensed-SemiBold";
  src: url("../assets/fonts/BarlowCondensed-SemiBold.ttf");
}

</style>
