import * as Sentry from '@sentry/vue';

import { useUserStore } from '../stores/user';
import { useApplicationStore } from '../stores/application';

export default function useSentry(error, isAxios) {
  const userStore = useUserStore();
  const applicationStore = useApplicationStore();

  Sentry.withScope(() => {
    Sentry.setTag('Axios', isAxios);
    Sentry.setContext('Error Response', error.response);
    if (Object.keys(userStore.user ?? {}).length) {
      Sentry.setUser({
        id: userStore.user.id,
        email: userStore.user.email,
      });
      Sentry.setContext('Organisation', {
        id: userStore.organisation.id,
        name: userStore.organisation.name,
      });
    }
    if (Object.keys(applicationStore.company ?? {}).length) {
      Sentry.setContext('Company', {
        id: applicationStore.company.id,
        name: applicationStore.company.name,
      });
    }
  });
}
