<template>
  <div :class="sizeStyle">
    <font-awesome-icon
      :icon="['fas', 'circle']"
      :fixed-width="true"
      class="animate-ping-slow text-sl-orange-700"
      :class="styles"
    />
    <font-awesome-icon
      :icon="['fas', 'circle']"
      :fixed-width="true"
      class="text-sl-orange-700"
      :class="styles"
    />
  </div>
</template>

<script>
export default {
  name: 'SitePing',
  props: {
    /**
      CSS Style Positioning for ping
     */
    styles: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['sm', 'xs'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    sizeStyle() {
      switch (this.size) {
        case 'xs':
          return 'text-2xs';
        case 'sm':
        default:
          return 'small-amount-indicator';
      }
    },
  },
};
</script>
