<template>
  <div
    class="toast small-body mb-4 rounded-md p-3 !text-white"
    :class="styles"
    data-testid="toast-message"
  >
    <div class="flex items-center">
      <font-awesome-icon
        v-if="toast.type === 'warning'"
        :icon="['fal', 'exclamation-triangle']"
        :fixed-width="true"
        data-testid="toast-message-warning-icon"
        class="mr-3"
      />
      <font-awesome-icon
        v-if="toast.type !== 'warning'"
        :icon="['fal', 'check']"
        :fixed-width="true"
        class="mr-3"
      />
      <p>{{ toast.message }}</p>
      <font-awesome-icon
        :icon="['fal', 'times']"
        :fixed-width="true"
        class="ml-3 cursor-pointer"
        data-testid="toast-message-close-icon"
        @click.prevent="remove"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteToast',
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  emits: ['remove-toast'],
  computed: {
    styles() {
      switch (this.toast.type) {
        case 'success':
          return 'bg-sl-green-600';
        case 'warning':
          return 'bg-sl-red-700';
        default:
          return 'bg-sl-orange-700';
      }
    },
  },
  methods: {
    remove() {
      this.$emit('remove-toast');
    },
  },
};
</script>
