import { growthbookPlugin } from '@slcyber/vue-feature-flag-provider';

export default {
  install: (app) => {
    app.use(growthbookPlugin, {
      apiHost: import.meta.env.VITE_GROWTHBOOK_URL,
      clientKey: import.meta.env.VITE_GROWTHBOOK_APP_KEY,
      enableDevMode: false,
      backgroundSync: true,
      subscribeToChanges: true,
      attributes: {
        product: 'darkiq',
      },
    });
  },
};
