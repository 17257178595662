<template>
  <div
    class="toast small-body mb-4 rounded-md bg-sl-blue-400 p-3"
    :class="collapsible ? 'hover:cursor-pointer' : ''"
    data-testid="enduring-toast"
    @click="toggleViewMode"
  >
    <div class="flex items-center">
      <SlLoading
        :text="toastText"
        size="x-small"
        colour="text-sl-black-600"
        text-colour="text-sl-black-600"
      />
      <font-awesome-icon
        v-if="viewExpanded && collapsible"
        :icon="['fal', 'times']"
        :fixed-width="true"
        class="ml-1"
      />
    </div>
  </div>
</template>

<script>

import { SlLoading } from '@slcyber/component-library';

export default {
  name: 'EnduringToast',
  components: {
    SlLoading,
  },
  props: {
    toast: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nonCollapsibleTypes: [
        'threat_queued',
      ],
      viewExpanded: true,
    };
  },
  computed: {
    toastText() {
      let translationString = this.toast.prefix;
      if (this.collapsible) {
        translationString += this.viewExpanded ? '.expanded' : '.collapsed';
      }
      return this.$t(translationString, {
        complete: this.toast.complete,
        total: this.toast.total,
      });
    },
    collapsible() {
      return !this.nonCollapsibleTypes.includes(this.toast.type);
    },
  },
  methods: {
    toggleViewMode() {
      if (this.collapsible) {
        this.viewExpanded = !this.viewExpanded;
      }
    },
  },
};
</script>
