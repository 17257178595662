import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5pluginsExporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5map from '@amcharts/amcharts5/map';
import * as am5geodataWorldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

am5.addLicense(import.meta.env.VITE_AM5CHARTS_LICENCE);
am5.addLicense(import.meta.env.VITE_AM5CHARTS_MAP_LICENCE);

export default {
  install: (app) => {
    const charts = {
      am5,
      am5xy,
      am5radar,
      am5percent,
      am5pluginsExporting,
      am5map,
      am5geodataWorldLow,
      am5themesAnimated,
    };
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$am5core = charts;
  },
};
